.timepicker {
  display: flex;
  align-items: center;

  div {
    flex: 1;
  }

  input {
    border: none;
    background: none;
    padding: 0;
    outline: none;
    font: inherit;
    text-align: center;
  }

  .placeholder {
    color: $grey-700;
  }

  &.disabled {
    input {
      color: rgba(27, 27, 27, 0.38);
    }
  }
}

/*.timepicker {
  position: relative;
}

.timepicker-toggle {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 16px;
  padding: 0 1rem;
  width: 100%;
  height: 52px;
  border: 1px solid $grey-700;
  background: $white;
  box-shadow: 0 0 0 0 $white inset;
  transition: none;

  rhd-icon {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 22px;
    color: $blue-500;
  }

  &:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 0 0 1px $grey-700 inset;
  }

  &:focus {
    box-shadow: 0 0 0 1px $blue-500 inset;
    border-color: $blue-500;
  }
}
*/

.timepicker-menu {
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  height: 0;
  width: 120px;
  padding: 8px;
  background: $white;
  box-shadow: 0 5px 15px 4px rgba($grey-450, 0.6);
  transition: all 0.1s ease-in-out;
  margin-top: 16px;
  overflow: hidden;

  &.is-open {
    opacity: 1;
    visibility: visible;
    height: 293px;
  }

  .hours,
  .minutes {
    max-height: 100%;
    margin: 0;
    padding: 0;
    flex: 0 0 calc(50% - 4px);
    position: relative;
  }

  button {
    display: block;
    text-align: center;
    padding: 9px 0;
    border-radius: 5px;
    background-color: $white;
    transition: background-color 0.2s;
    cursor: pointer;
    width: 100%;
    height: 35px;
    font-size: 16px;

    &:hover {
      background-color: $blue-50;
    }

    &.is-active {
      background-color: $blue-500;
      color: $white;
    }
  }

  button + button {
    //margin-top: 0.5rem;
  }
}
