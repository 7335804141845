/* Tile Preview Component */
rnb-tile-preview {
  display: block;
  min-width: 220px;
}

article.tile-preview {
  display: flex;
  flex-direction: column;
}

.tile-preview-header {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;
  background-color: #06c;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  img {
    aspect-ratio: 16/9;
    position: absolute;
    display: block;
    width: 100%;
    object-fit: cover;
    border-bottom: 2px solid #FAC9B5;
  }
}

.tile-preview-body {
  aspect-ratio: 291/316;
  display: flex;
  flex-direction: column;
  // flex-basis: 189px;
  border-color: $grey-500;
  border-style: solid;
  border-width: 0 1px 1px;
  // max-width: 295px;

  .title {
    height: 43%;
    overflow: hidden;
    padding: 16px 16px 6px;
    p {
      color: #706b69;
      margin: 0;
    }
  }
  .description {
    height: 57%;
    overflow: hidden;
    padding: 6px 16px 0;
  }
  .description {
    flex: 1;
    color: $grey-900;
    overflow-wrap: break-word;
    p {
      color: #706b69;
      margin: 0;
    }
  }

  .footer {
    z-index: 1;
    background: white;
    padding: 12px 16px 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 24px;
    color: #706b69;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 4px;

    flex-basis: 34px;
    p {
      margin: 0;
    }
    rhd-icon {
      color: #706b69;
      font-size: 18px;
    }
  }
}

// .tile-preview-compact {
//   width: 220px;
//   height: 220px;
// }

// .tile-preview-full {
//   .tile-preview-header {
//     height: 295px;
//   }
// }
