$rds-font-path-prefix: '/assets/fonts/' !default;

.rds-form-field__subscript {
  display: flex;
  flex: 1;
  .right {
    margin-left: auto;
  }
}
//@import './styles';
