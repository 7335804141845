.empty-table-cta {
  padding: 55px 0;
  background: $white;
  text-align: center;
  border-bottom: 1px solid $grey-300;

  img {
    width: 233px;
    height: 233px;
  }

  h3 {
    margin-top: 25px;
  }

  p {
    color: $grey-700;
    max-width: 385px;
    margin: 0 auto;
    line-height: 1.5265;
  }

  .button-wrapper {
    margin-top: 25px;
  }
}
