/* Search Component */
.search-input {
  padding: 6px 21px 7px 14px;
  display: flex;
  border: 1px solid $grey-500;
  border-radius: 5px;
  font-size: 16px;
  line-height: 25px;
  background: $white;
  align-items: center;
  cursor: text;

  &:hover {
    border-color: $blue-400;
  }

  &-active {
    border-color: $blue-500;

    rhd-icon {
      color: $blue-500 !important;
    }
  }

  input {
    flex: 1;
    border: none;
    background: 0 0;
    font-size: 16px;
    margin-left: 13px;
  }

  rhd-icon {
    font-size: 22px;
    color: $grey-600;
    margin-bottom: -5px;

    &.close {
      cursor: pointer;
    }
  }
}
