/* Form Elements */
div.form-container {
  margin-bottom: 32px;
  background-color: $white;

  .form-step {
    padding: 32px 48px;
  }
}

.shadow-default {
  box-shadow: 0.5px 0.5px 15px 4px rgba($grey-450, 0.3);
}

.shadow-strong {
  box-shadow: 0.5px 0.5px 15px 4px rgba($grey-450, 0.6);
}

div.form-body {
  form + form {
    margin-top: 32px;
  }
}

div.form-step {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  cursor: pointer;

  span.step {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 18px;
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
    height: 48px;
    width: 48px;
    border: 1px solid $grey-400;
    box-shadow: 0 2px 4px 0 rgba($black, 0.2);
    border-radius: 100%;
    color: $grey-400;
    margin-right: 48px;

    &.inactive {
      color: $white;
      background-color: $blue-500;
      border: 1px solid $blue-500;
    }
  }

  .step-title {
    flex: 1;
    color: $grey-700;
    font-size: 22px;
    font-weight: bold;
  }

  .step-title-optional {
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    margin-left: 0.6rem;
  }

  .step-actions {
    font-weight: normal;
  }

  .step-add {
    pointer-events: none;
    width: 80px;
  }

  &.disabled {
    cursor: default;
    color: $grey-500;

    span.step {
      color: $grey-500;
      background-color: $grey-200;
      border: 1px solid $grey-200;
    }
  }
}

div.form-body {
  padding: 48px 285px 48px 144px;

  &.form-body-tight {
    padding: 48px;
  }

  &.form-body-grey {
    padding: 0;
    background-color: $grey-200;
  }
}

div.editor-actions {
  padding: 44px 52px 44px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  rhd-button + rhd-button,
  rhd-button + rnb-simple-select {
    margin-left: 24px;
  }
}

div.editor-container {
  padding: 0 170px 5px 170px;

  div.disclaimer {
    margin-bottom: 24px;
  }
}

div.editor-container {
  padding: 0 170px 5px 170px;
}

div.form-sub-header {
  display: flex;
  padding: 24px 57px 23px 48px;
  cursor: pointer;

  .form-sub-header-label {
    flex: 1;
    color: $grey-900;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;

    .form-sub-header-hint {
      color: $grey-700;
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
    }
  }

  .form-sub-header-actions {
    display: flex;
    align-items: center;

    span.mat-button-wrapper {
      font-size: 12px;
      line-height: 16px;
    }

    rhd-badge {
      margin: 0 24px;
    }

    rhd-button + rhd-icon {
      margin-left: 24px;
    }

    rhd-icon {
      font-size: 30px;
      color: $blue-500;
      line-height: 56px;
    }
  }
}

div.form-vertical-split {
  display: flex;
  padding: 48px 97px 48px 96px;

  &.hidden {
    display: none;
  }

  .form-vertical-split-left {
    flex: 1;
  }

  .form-vertical-split-right {
    flex-basis: 295px;
    margin-left: 90px;
  }
}

.form-body mat-divider.spacer {
  margin: 32px 0 48px;
}

mat-divider.spacer,
mat-divider.spacer-even {
  margin: 48px 0;
}

div.form-actions {
  padding: 29px 48px 30px 48px;
  display: flex;
  justify-content: flex-end;

  &.form-actions-center {
    padding: 47px 0 0 0;
    text-align: center;
  }

  &-announcement {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 16px;
    .pagination {
      display: flex;
      align-items: center;
      vertical-align: middle;
      rhd-icon {
        cursor: pointer;
        font-size: 25px;
        span {
          vertical-align: middle;
        }
      }
    }
  }

  button,
  rhd-button {
    min-width: 94px;
  }

  button + button,
  rhd-button + rhd-button,
  .button-wrapper + rhd-button {
    margin-left: 24px;
  }

  &.form-actions-bottom {
    padding: 29px 0 30px 0;
  }
}

.page-header div.page-actions,
div.form-actions {
  .button-wrapper {
    display: inline-block;
    margin-left: 24px;

    rhd-button {
      margin-left: 0;
    }
  }
}

div.form-control {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 0 16px 0;

  &.no-margin {
    margin-bottom: 0;
  }

  &.big-margin {
    margin-bottom: 32px;
  }

  &.form-control-hint {
    margin-bottom: 24px;
  }

  .form-label {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    flex: 0 0 215px;
    margin: 15px 0 0 0;
    padding-right: 28px;
    color: $grey-900;
    &.form-label-no-margin {
      margin: 0;
    }

    .form-label-hint {
      margin: 16px 0 0 0;
      padding: 0;
      color: $grey-700;
      font-size: 12px;
      font-style: italic;
      font-weight: normal;
      line-height: 16px;
      &.no-space {
        margin-top: 0;
      }
    }
  }

  .form-input {
    flex: 1;

    &--align-with-label {
      margin-top: 15px;
    }

    &--toggle-text {
      margin-left: 32px;
    }

    &.form-input-dropdown {
      margin: 40px 0;
    }

    &.form-input-align-right {
      text-align: right;
    }

    mat-select .mat-select-arrow {
      color: $blue-500;
    }

    .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
      transform: translateY(-10%);
    }
  }
}

mat-form-field {
  width: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $grey-700;
}

.mat-form-field-infix {
  border-width: 5px !important;
}

rnb-user-select, rnb-topic-select {
  .mat-form-field-prefix,.mat-form-field-suffix {
    font-size: 24px !important;
  }
  
  .mat-form-field-suffix {
    cursor: pointer;
  }
}

rnb-topic-select {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

.mat-form-field mat-icon {
  color: $blue-500;
}
