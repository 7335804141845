@import 'node_modules/@rds/core/styles/abstracts/functions';
@import 'node_modules/@rds/core/styles/abstracts/mixins';

.news-content-preview {
  &.desktop {

  }
  &.mobile {
    max-width: 375px;
    margin: auto;
    overflow:hidden;
    .container {
      width: 100%;
      max-width: 1672px;
      padding-right: 20px;
      padding-left: 20px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .purple-icon {
    fill: #7D0096;
  }
  .website-link {
    display: flex;
    align-items: center;
    gap: 7px;
    .rds-icon {
      width: 18px;
      height: 18px;
    }
  }
  .news-column {
    display: flex;
    flex-direction: column;
    gap: rds-layout(5);
  }
  .thumbnail {
    aspect-ratio: 16 / 9;
    img {
      width: 100%;
      height: 100%;  
      object-fit: cover;
    }
  }
  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .info-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .info {
      .actions {
        display: flex;
        gap: rds-spacing(7);
        .action {
          display: flex;
          align-items: center;
          &__count {
            margin-left: 5px;
            font-family: 'Roche Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            /* or 200% */
          }
        }      
      }
    }
  }
  .channels-col, .topics-col {
    display: flex;
    align-items: center;
    gap: rds-spacing(3);

  }
  rds-chip-list {
    display: flex;
    flex-wrap: wrap;
    gap: rds-spacing(3);
    .no-click {
      pointer-events: none;
    }
    .rds-chips__chip--mandatory {
      cursor: default;
      rds-icon {
        cursor: pointer;
      }
    }
    .reverse-chip-icon {
      flex-direction: row-reverse;
      .rds-chip__leading-icon {
        margin: 0 -4px 0 4px;
      }
    }
  }
  ::ng-deep {
    .rds-menu-item {
      &:hover {
        rds-icon.invisible-icon {
          color: rds-color('states-solid-hover-select');
        }
      }
      rds-icon.invisible-icon {
          color: rds-color('white');
      }
    }
  }
}
