/* Disclaimer Component */
div.disclaimer {
  display: flex;
  padding: 16px 22px 16px 16px;
  margin-bottom: 48px;
  background-color: $white;

  &.disclaimer-info {
    border: 0.5px solid $blue-500;
    border-radius: 2px;

    .disclaimer-icon {
      color: $blue-500;
      font-size: 25px;
    }

    .disclaimer-text {
      color: $grey-900;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      .disclaimer-hint {
        color: $grey-700;
        font-weight: normal;
      }
    }

    .disclaimer-actions {
      color: $grey-500;
      font-size: 20px;
    }
  }

  .disclaimer-icon {
    margin: auto 0;
  }

  .disclaimer-text {
    flex: 1;
    margin: 0 30px 0 18px;

    p {
      margin: 0;
      padding: 0;

      &.disclaimer-hint {
        padding-top: 4px;
      }
    }
  }

  .disclaimer-actions {
    margin: auto 0;
    text-align: right;

    rhd-icon {
      cursor: pointer;
    }
  }
}
