/* Footer */
rnb-footer {
  flex-shrink: 0;
}

footer.page-footer {
  position: relative;
  width: 100%;
  height: 200px;
  border-top: 1px solid rgba($grey-700, 0.25);
  background-color: $grey-200;
  box-shadow: 0 -5px 24px 0 rgba($black, 0.07);
  font-size: 16px;
  line-height: 21px;

  .footer-container {
    width: 830px;
    margin: 0 auto;

    .footer-links {
      padding: 37px 0 29px;

      a::before {
        font-family: 'rochehomeicons' !important;
        content: '';
        font-size: 20px;
        vertical-align: text-bottom;
      }

      a + a {
        margin-left: 25px;
      }

      .disclaimer {
        cursor: pointer;
      }
    }

    .footer-disclaimer {
      padding-top: 16px;
      color: $grey-blue-500;
    }
  }
}
