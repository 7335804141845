/* Sidebar */
div.sidebar-header {
  display: flex;
  padding: 48px 52px 96px 52px;
  align-items: center;

  h1 {
    flex: 1;
    color: $grey-900;
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
  }

  rhd-button {
    margin-right: 52px;
    padding-top: 6px;

    button {
      font-size: 28px !important;
    }
  }
}

div.sidebar-content {
  padding: 0 48px;

  h2 {
    color: $grey-900;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 24px;

    &.heading-with-icon {
      rhd-icon {
        position: relative;
        top: 2px;
        top: -6px;
        color: $grey-500;
      }
    }
  }

  h2 + .mat-form-field {
    margin-top: -8px;
  }

  h3 {
    color: $grey-900;
    font-size: 18px;
    line-height: 25px;
    font-weight: normal;
  }

  rnb-select {
    display: block;
  }

  rnb-select + rnb-select {
    margin-top: 40px;
  }

  .sidebar-actions {
    display: flex;
    margin: 48px 0;
    justify-content: flex-end;

    rhd-button.less-padding button {
      padding: 12px 25px 11px !important;
    }

    rhd-button.bit-less-padding button {
      padding: 12px 47px 11px !important;
    }

    rhd-button + rhd-button {
      margin-left: 16px;
    }
  }

  .channel-info {
    h2 {
      margin-bottom: 0;

      &:nth-of-type(2) {
        margin-top: 48px;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.39;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .audience-title {
    margin-bottom: 8px;
  }

  .audience-hint {
    display: block;
    margin-top: 0;
    margin-bottom: 24px;
    font-style: italic;
  }
}
