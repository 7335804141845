.ngx-tour_backdrop {
  opacity: 0.2;
  border-radius: 4px;
}

div.tour-step {
  max-width: 26rem;
  display: block;
  position: relative;
  padding: 16px;
  overflow: visible;

  &::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 17.3px 10px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -16px;
    left: 16px;
  }

  &.mat-menu-below {
    margin-top: 18px;

    &.mat-menu-before::before {
      left: auto;
      right: 16px;
    }
  }

  &.mat-menu-above {
    margin-bottom: 18px;

    &::before {
      transform: rotate(180deg);
      top: auto;
      bottom: -16px;
    }

    &.mat-menu-before::before {
      left: auto;
      right: 16px;
    }
  }

  h3 {
    margin-top: 0;
    color: $grey-900;
  }

  .mat-card-content {
    padding-right: 24px;

    p {
      color: $grey-900;
      font-size: 16px;
      line-height: 25px;
      margin: 0 0 4px;
      white-space: pre-wrap;
    }
  }

  .mat-card-actions {
    display: flex;
    width: 100%;
    padding-top: 12px;
    justify-content: flex-end;
  }

  .mat-primary {
    margin-left: 12px;
    min-width: 8rem;
  }
}

button.tour-step--close {
  position: absolute;
  top: 2px;
  right: 2px;
  color: $grey-700;
}
