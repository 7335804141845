/* Navigation Tabs */
a.mat-tab-link,
a.mat-tab-link:visited {
  font-size: 16px;
  opacity: 1;
  color: $grey-900;
  font-weight: normal;

  &.mat-tab-label-active {
    color: $blue-500;
  }
}

.mat-tab-header {
  background-color: $white;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid $grey-400; // 2px screws with active bar, need to figure out how to increase both
}

.mat-tab-link {
  height: 64px !important;
}
