/*
.datetime-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 26px;

  .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0;
  }
}
.datetime-input {
  display: inline-block;

  &.date {
    width: 192px;
    // firefox display glitch fixes
    height: 51px;

    .mat-form-field-outline {
      height: 51px;
    }
  }

  &.time {
    width: 149px;
  }

  + .datetime-input {
    margin-left: 24px;
  }
}

.datetime-wrapper rnb-timezone-picker {
  margin-left: 24px;

  .timezone {
    width: 149px;
  }
}
*/

.datetime-wrapper {
  display: flex;
  align-items: baseline;

  rnb-timezone-picker {
    margin-left: 24px;

    .timezone {
      width: 149px;
    }
  }
}

.datetime-input {
  display: inline-block;

  &.date {
    width: 192px;
  }

  &.time {
    width: 149px;
  }

  + .datetime-input {
    margin-left: 24px;
  }
}

.datetime-input.time {
  .mat-form-field-suffix {
    rhd-icon {
      font-size: 22px;
      color: $blue-500;
      margin: 0 7px;
    }
  }
}

// fix for material datepicker icon alignment being off in FireFox
@supports (-moz-appearance: none) {
  .datetime-input.date mat-datepicker-toggle {
    button {
      height: 24px !important;
    }
  }
}
