div.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;

  div.page-title {
    flex: 1;
    display: flex;
    align-items: center;

    h1 {
      margin: 0;
      color: $grey-700;
    }

    rhd-button {
      margin-left: 25px;
    }

    rhd-icon {
      font-size: 32px;
    }
  }
  div.page-actions {
    text-align: right;

    rhd-button {
      display: inline-block;
      margin-left: 24px;
    }
  }

  &.page-header-top {
    padding-top: 40px;
  }
}
