/* Table Styles */
table.mat-table {
  width: 100%;
  border-top: 1px solid $blue-100;
}

.mat-cell,
.mat-footer-cell {
  font-size: 16px !important;
  color: $grey-700 !important;
}

.filter-control {
  display: flex;
  align-items: center;
  padding: 13px 23px 14px;
  background-color: $white;

  .label {
    white-space: nowrap;
    margin-right: 15px;
  }

  rnb-search {
    flex-basis: 300px;
  }

  rnb-simple-select {
    margin-left: 32px;
  }
}

th.mat-header-cell {
  color: $blue-500;
  background-color: $grey-200;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;

  &.mat-column-action {
    width: 48px;
  }

  &.mat-column-role {
    min-width: 80px;
  }
}

tr.mat-row,
tr.mat-header-row {
  height: 64px !important;
}

.mat-row {
  background-color: $white;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: $blue-75;
  }
}

.mat-column-action {
  // text-align: right;

  .mat-icon-button {
    color: $blue-500;
  }
}

.table-channels th.mat-column-role {
  padding: 0 11px;
}

.table-channels td.mat-column-role,
.table-news td.mat-column-type {
  padding-left: 35px;
}

.table-news .pin {
  button {
    color: $grey-600;
  }

  .is-active {
    color: $blue-500;
  }
}

td.mat-column-pin.cdk-column-pin:first-of-type {
  padding: 0 !important;
  width: 48px;

  button {
    width: 100%;
    height: 63px;
    cursor: pointer;
  }

  .mat-icon {
    max-width: 20px;
    max-height: 20px;
  }
}

// .mat-column-owners,
// .mat-column-managers,
// .mat-column-publishers,
// .mat-column-assign
td.mat-cell {
  white-space: nowrap;
  transition: background-color 0.4s;

  &.is-duplicated {
    background-color: $blue-50;
  }
}

.suggestion-approval {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;

  a.reject {
    color: $red-500;
  }

  a.approve {
    margin-left: 32px;
  }
}

th.table-right,
td.table-right {
  text-align: right !important;
}

th.table-center,
td.table-center {
  text-align: center !important;
}

.role-icon,
.type-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: rgba($teal-500, 0.08);
  border-radius: 100%;
  text-align: center;

  rhd-icon {
    font-size: 22px;
    position: relative;
    top: 4px;
  }
}

.type-icon {
  &-link {
    color: $orange-500;
    background-color: rgba($orange-500, 0.08);
  }

  &-content {
    color: $purple-500;
    background-color: $purple-100;
  }
  &-announcement {
    color: $teal-500;
    background-color: $teal-100;
  }
}

.role-icon {
  &-owner {
    color: $teal-500;
  }

  &-manager {
    color: $orange-500;
    background-color: $orange-100;
  }

  &-publisher {
    color: $red-700;
    background-color: $grey-100;
  }

  &-admin {
    color: $teal-500;
  }

  &-super-admin {
    color: $red-700;
    background-color: $grey-100;
  }
}

.user-table td.mat-column-avatar img {
  max-height: 55px;
  padding-top: 3px;
}

.table-truncate-wrapper {
  position: relative;
  display: inline-block;

  .total-users {
    position: absolute;
    right: 0;
  }
}

.table-truncate {
  display: inline-block;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: pre !important;
}

.spinner-container {
  background: $white;
  padding: 32px 0 443px;
  text-align: center;
  border-bottom: 1px solid $grey-300;
}
