div.image-upload-area {
  position: relative;
  width: 373px;
  height: 122px;
  border: 1px dashed $blue-600;
  background-color: $grey-200;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  min-height: 104px;

  &.image-upload-area-selected {
    border: 1px solid $grey-400;
    background-color: $grey-100;
  }

  &.image-upload-area-error {
    border: 1px dashed $red-500;
    background-color: $red-100;
  }

  div.image-select {
    padding: 20px 75px;
    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  div.image-preview-wrapper {
    display: flex;
    justify-content: center;
    min-height: 104px;
  }

  div.image-preview {
    display: flex;
    padding: 24px 19px 12px 16px;

    .image-wrapper {
      flex-basis: 81px;
      max-height: 80px;
      margin: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    div.image-info {
      flex: 1;
      margin: auto 0 auto 16px;
      text-align: left;
      color: $grey-900;
      font-size: 16px;
      line-height: 25px;
      overflow-wrap: anywhere;

      .image-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .image-size {
        color: $grey-700;
        font-size: 12px;
        line-height: 16px;
      }
    }

    div.image-actions {
      flex-basis: 18px;
      text-align: right;
      color: $grey-700;
      font-size: 25px;
      margin: auto 0;

      rhd-icon {
        cursor: pointer;
      }
    }
  }
}

.image-upload-message {
  display: flex;
  position: absolute;
  color: $grey-700;
  font-size: 12px;
  border: 1px dashed;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;

  .image-upload-cancel {
    cursor: pointer;
    position: absolute;
    right: 9px;
    top: 10px;
    font-size: 1.9em;
    color: $grey-600;
  }

  .image-upload-message-content {
    margin: auto;

    p {
      margin: 0;
      line-height: 1;
    }
  }

  .image-upload-message-indicator {
    font-size: 28px;

    .lds-ring {
      width: 28px;
      height: 28px;
    }
  }

  &.processing {
    border-color: $blue-500;
    background: $blue-50;
  }

  &.failed {
    border-color: $red-500;
    color: $red-500;
    background: $red-100;
    cursor: pointer;

    .image-upload-message-indicator {
      color: $red-500;
    }

    strong {
      color: $blue-500;
    }
  }

  &.complete {
    border-color: $green-500;
    background: $green-100;

    .image-upload-message-indicator {
      color: $green-500;
    }
  }
}

.image-upload-hint {
  color: $grey-700;
  font-size: 12px;
  display: block;
  font-style: italic;
  line-height: 1;
  text-align: left;
  margin-top: 0.6666666667em;
}

.image-upload-error {
  color: $red-500;
  display: block;
  font-weight: 400;
  line-height: 1.125;
  font-size: 75%;
  letter-spacing: 1.5px;
  margin-top: 0.6666666667em;
  text-align: left;
}