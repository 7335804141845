/* Sidenav Component */
.sidenav-container {
  height: 100%;
}

.mat-drawer-container {
  background-color: $grey-200 !important;
}

mat-sidenav {
  width: 645px;
}

/* Page Header Component */
div.return-action {
  padding-top: 24px;
  margin-bottom: 45px;
  color: $blue-500;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;

  a {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    rhd-icon {
      font-size: 30px;
      margin-right: 8px;
    }
  }
}
