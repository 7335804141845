/* Pagination Styles */
.mat-paginator-container {
  min-height: 64px !important;
  justify-content: center !important;
}

.mat-paginator {
  font-size: 16px;
  line-height: 25px;
  color: $grey-900 !important;
}
