rnb-news-select {
    .filter-control {
        border-top: 1px solid #cad4dd;
    }

    td.mat-cell:first-of-type {
      padding: 0 20px 5px 20px !important;
    }

    .table-news td.mat-column-type {
      padding-left: 5px !important;
      padding-right: 5px;
    }
}
