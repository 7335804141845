/* Main Container */
$containerWidth: 1260px;
$headerHeight: 86px;
$footerHeight: 200px;

main.main-container {
  width: 100%;
  margin: 0 auto;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  > .content {
    margin-top: 96px;
    padding-bottom: 186px;
    flex: 1;
  }
}

rnb-container,
.sidenav-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
