$white: #ffffff;
$black: #000;
$transparent: rgba($white, 0%);

$grey-100: #fafafa; //rgb(250,250,250)
$grey-200: #f9f9fa; //rgb(249, 249, 250)
$grey-300: #e3e3e3; //rgb(227, 227, 227)
$grey-400: #d6d6d6; //rgb(214, 214, 214)
$grey-450: #cccccc; //rgb(204,204,204)
$grey-500: #adadad; //rgb(173, 173, 173)
$grey-600: #858585; //rgb(133, 133, 133)
$grey-700: #666666; //rgb(102, 102, 102)
$grey-900: #333333; //rgb(51, 51, 51)

$blue-50: #f2f7fc; //rgb(242, 247, 252)
$blue-75: #e5effa; //rgb(229, 239, 250)
$blue-100: #cad4dd; //rgb(202, 212, 221)
$blue-150: #cce0f5; //rgb(204, 224, 245)
$blue-300: #99c2eb; //rgb(153, 194, 235)
$blue-400: #66a3e0; //rgb(102, 163, 224)
$blue-500: #0066cc; //rgb(0, 102, 204)
$blue-600: #004d99; //rgb(0, 77, 153)

$grey-blue-500: #667081; //rgb(102, 112, 129)

$orange-100: #fef9f2; //rgb(254, 249, 242)
$orange-500: #c88000; //rgb(200, 127, 0)

$red-100: #fef2f6;
$red-500: #e40046; //rgb(228, 0, 70)
$red-700: #990026; //rgb(153, 0, 38)

$green-100: #f2faf7;
$green-500: #00965e;

$purple-100: #faf7fb; //rgb(250, 247, 251)
$purple-500: #784788; //rgb(120, 71, 136)
$teal-500: #00acb3; //rgb(0, 173, 179)
$teal-100: #F2FEFE; //rgb(0, 173, 179)
$teal: #00acb3; //rgb(0, 172, 179)

// Primary colors
$roche-blue: $blue-500;
$roche-black: #1b1b1b;
$roche-grey: $grey-900;

// Primary colors shades
$blue-5: $blue-50; //rgb(242, 247, 252)
$blue-10: $blue-75; //rgb(229, 239, 250)
$blue-20: $blue-150; //rgb(204, 224, 245)
$blue-40: $blue-300; //rgb(153, 194, 235)
$blue-60: $blue-400; //rgb(102, 163, 224)
$blue-dark: $blue-600; //rgb(0, 77, 153)

$grey-light-5: #f2f2f2; //rgb(242, 242, 242)
$grey-light-10: #eaeaea; //rgb(234, 234, 234)
$grey-light-20:$grey-400; //rgb(214, 214, 214)
$grey-light-40: $grey-500; //rgb(173, 173, 173)
$grey-light-60: $grey-600; //rgb(133, 133, 133)
$grey-light-80: $grey-700; //rgb(102, 102, 102)
$grey-dark: #262626; //rgb(38, 38, 38)
