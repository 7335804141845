
.crop-modal {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 768px;
  padding: 40px 80px 0;

  &__content {
    max-height: 70vh;
    overflow: hidden;
    overflow-y: auto;
  }
  &__btns {
    display: flex;
    margin: 64px auto;

    &__submit {
      margin-left: 16px;
    }
  }
}
