/* Global Styles */
$desktopBreakpoint: 1280px;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'RocheSans', 'Helvetica Neue', sans-serif;
  background-color: $grey-200;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

.rotate45 {
  transform: rotate(45deg);
}

button {
  background: $transparent;
  border: 0;
  padding: 0;
}

input::placeholder {
  color: $grey-700 !important;
  transition: none !important;
  opacity: 1;
}

.desktop-only {
  display: none !important;

  @media (min-width: $desktopBreakpoint) {
    display: flex !important;
  }
}

.mobile-only {
  @media (min-width: $desktopBreakpoint) {
    display: none;
  }
}
