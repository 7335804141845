/* Dialog Component */
.mat-dialog-container {
  position: relative;
  padding: 0 !important;

  button.mat-dialog-close {
    display: block;
    position: absolute;
    top: 2px;
    right: 2px;
    color: $grey-700;

    rhd-icon {
      font-size: 26px;
    }
  }
}

rnb-confirm-dialog,
rnb-news-rejection-dialog,
rnb-news-create-language-dialog {
  display: block;
  padding: 64px 54px;

  h2.mat-dialog-title {
    color: $grey-900;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 48px;
  }

  .mat-dialog-title {
    display: flex;
    color: $grey-700;
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    rhd-icon {
      font-size: 28px;
      margin-right: 12px;

      &.info {
        color: $blue-500;
      }

      &.danger {
        color: $red-500;
      }
    }
  }

  .mat-dialog-content {
    padding-top: 7px;

    p {
      text-align: center;
      margin: 0 0 4px;
      font-size: 16px;
      line-height: 25px;
      color: $grey-700;

      &.title {
        color: $grey-900;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
      }

      /*&.message {
        color: $grey-700;
      } */

      &.reason {
        margin-bottom: 24px;
      }

      &.info {
        color: $grey-900;
      }
    }

    div.selection {
      margin: 48px 98px;

      p {
        text-align: left;
        color: $grey-900;
        font-size: 18px;
        line-height: 25px;
      }
    }

    div.options {
      margin: 44px 0 0 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      //align-items: center;

      .mat-radio-button {
        display: block;
        margin: 0 0 36px;

        .mat-radio-container {
          width: 28px;
          height: 28px;

          .mat-radio-outer-circle {
            width: 28px;
            height: 28px;
          }

          .mat-radio-inner-circle {
            width: 28px;
            height: 28px;
          }
        }

        .mat-radio-label-content {
          padding-left: 18px;
        }
      }
    }
  }

  .mat-dialog-actions {
    margin-top: 64px;
    justify-content: center;

    &.less-margin {
      margin-top: 48px;
    }

    &.no-margin {
      margin-top: 0;
    }

    rhd-button + rhd-button {
      margin-left: 16px;
    }

    button {
      min-width: 148px;
    }
  }
}

rnb-preview-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;

  .mat-dialog-title {
    display: grid;
    width: 100%;
    max-width: 848px;
    min-height: 106px;
    margin: 0 auto;
    padding: 48px 0 12px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: '. . .';

    &.embedded {
      justify-content: space-between;
    }
  }

  .language-select {
    grid-column: 3;
    display: flex;
    justify-content: flex-end;
  }

  .view-mode-toggle {
    grid-column: 2;
    display: flex;
    justify-content: center;
    align-content: center;

    > div {
      display: block;
      margin: 6px 0;
      border-radius: 24px;
      background-color: $grey-light-5;
      height: 36px;
    }

    button.rhd__btn.mat-primary {
      border-radius: 24px;
      padding-right: 24px;
      padding-left: 24px;
    }

    button.rhd__btn--type-flat__color-primary {
      background-color: transparent;
    }
  }

  .content-mode-toggle {
    grid-column: 1;

    .is-active button {
      background-color: $blue-150;
    }
  }

  .mat-dialog-content {
    display: flex;
    justify-content: center;
    padding: 0 48px;
    margin: 0;
    flex: 1;
  }

  .preview-container-link {
    display: flex;
    justify-content: center;

    rnb-tile-preview {
      margin-bottom: 32px;
    }

    .active-preview {
      width: 295px;
      flex: 1 0 auto;

      &-actions {
        text-align: center;
      }
    }

    .available-languages {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-left: 64px;
      margin: 0 -24px;

      li {
        padding: 0 24px 24px;
      }

      article {
        border: 1px solid rgba($grey-700, 0.25);
        cursor: pointer;
      }

      .is-active article {
        border-color: $blue-500;
      }
    }

    .avail-previews {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 64px;
    }
  }

  .preview-container-embedded {
    position: relative;
    height: 100%;
    margin-top: 12px;
  }

  .mat-dialog-actions {
    margin: 0;
    justify-content: flex-end;
    height: 96px;
    padding: 0 48px;
    box-shadow: 0 -2px 4px 0 rgba(222, 222, 222, 0.65);

    rhd-button {
      margin-left: 24px;
    }
  }

  mat-divider {
    margin: 47px 0 0 0 !important;
  }

  rhd-button.mat-dialog-close {
    position: absolute;
    right: 0px;
  }
}

rnb-tour-dialog {
  video {
    width: 100%;
  }

  &.top-full-width {
    display: block;
    max-width: 540px;
    padding: 32px 1rem 16px;
    margin: auto;
    border-radius: 0;

    h2.mat-dialog-title {
      color: $grey-900;
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 16px;
    }

    .mat-dialog-content {
      p {
        margin: 0 0 34px;
        font-size: 16px;
        line-height: 25px;
        color: $grey-900;
        white-space: pre-wrap;
      }
    }

    .mat-dialog-actions {
      justify-content: flex-end;
      margin-bottom: 0;

      rhd-button:first-child {
        min-width: 8rem;
      }
      rhd-button:last-child {
        margin-left: 8px;
        min-width: 12rem;
      }
    }
  }

  &.full-page {
    width: 100%;
    max-width: 1260px;
    display: block;
    padding: 15vh 16px 16px;
    margin: auto;
    border-radius: 0;

    h2.mat-dialog-title {
      color: $blue-500;
      font-size: 48px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 24px;
    }

    .mat-dialog-content {
      p {
        margin: 0 0 24px;
        font-size: 42px;
        line-height: 54px;
        color: $blue-500;
        white-space: pre-wrap;
      }
    }
  }

  &.default {
    display: block;
    max-width: 840px;
    padding: 120px 0;
    margin: auto;

    h2.mat-dialog-title {
      color: $grey-900;
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 16px;
    }
  }
}

.data-privacy-dialog {
  padding: 64px 64px 0;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: $grey-700;
    margin-bottom: 32px;

    rhd-icon {
      color: $roche-blue;
      font-size: 1.7em;
      line-height: 1;
      margin-right: 11px;
    }
  }

  h2 {
    text-align: center;
    font-weight: bold;
    //margin-bottom: -0.4em;
  }

  p {
    color: $grey-700;
    text-align: center;
    line-height: 1.56;

    br {
      display: block;
      margin-bottom: 1em;
    }
  }

  ul {
    margin-top: 24px;
    padding: 0 58px;
    list-style: disc;

    li {
      padding-left: 5px;
      display: list-item;

      + li {
        margin-top: 16px;
      }

      div {
        display: inline;
        p {
          text-align: left;
        }
      }
    }


  }
  .mat-dialog-actions {
    padding: 0 46px 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 30px;

    rhd-button:first-of-type {
      margin-right: 24px;
    }

    button {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    &.confirmed {
      justify-content: center;
    }
  }
}
