/* Topic Select Component */
rnb-topic-select {
  display: inline-block;
  width: 100%;
}

div.topic-select-container {
  //margin: 48px 0;
  cursor: text;

  &.topic-select-container-active {
    .topic-select {
      border-color: $blue-500;
    }

    rhd-icon {
      color: $blue-500;
    }
  }

  .topic-select {
    padding: 11px 13px 11px 11px;
    display: flex;
    border: 1px solid $grey-500;
    font-size: 24px;
    line-height: 21px;
    background: $white;

    input {
      flex: 1;
      border: none;
      background: 0 0;
      font-size: 16px;
    }

    rhd-icon {
      cursor: pointer;
    }
  }

}

.topic-selected-container {
  flex-basis: 36px;
  border: 1px solid $grey-400;
  line-height: 19px;
  border-top: none;
  padding: 16px;

  mat-chip:not(.chip-placeholder) {
    color: $blue-500;
    border-color: $blue-300;

    .chip-name-wrapper {
      display: inline-block;
      overflow: hidden;
      height: 19px;
      max-width: 410px;
      white-space: pre;
      text-overflow: ellipsis;
    }

    mat-icon {
      color: $blue-500 !important;
      opacity: 1 !important;
    }
  }
}

.topic-hint {
  margin-top: 0.6666666667em;
  font-size: 75%;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: normal;
  font-style: italic;
  color: rgba(27, 27, 27, 0.6);
}

.topic-error {
  color: $red-500;
}

.topic-selected {
  display: flex;
  flex-direction: row;

  .topic-avatar {
    flex-basis: 40px;
    margin: auto 0;

    img {
      max-width: 40px;
      max-height: 40px;
    }
  }

  .topic-info {
    flex: 1;
    color: $grey-900;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 12px;

    .topic-comment {
      color: $grey-700;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
    }
  }

  .topic-delete {
    flex-basis: 18px;
    font-size: 25px;
    color: $grey-700;
    margin: auto 0 auto 18px;

    rhd-icon {
      cursor: pointer;
    }
  }
}
.mat-option.loading {
  text-align: center;
  padding: 140px 0 120px;
}

.topic-selected .topic-identifier,
.mat-option .topic-identifier {
  color: $grey-500;
  font-style: italic;
}
