/* Login */
div.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.login {
  width: 380px;
  padding: 48px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  h1 {
    color: $grey-700;
    font-size: 44px;
    font-weight: bold;
    line-height: 55px;
  }

  .header {
    margin-bottom: 28px;
  }

  .error {
    color: $red-500;
    margin-top: 20px;

    &-header {
      font-size: 18px;
      color: $grey-700;
      margin: 0 auto;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;

      rhd-icon {
        font-size: 28px;
        color: $red-500;
        margin-right: 12px;
      }
    }
  }
}
