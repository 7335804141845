.taxonomy-item {
  display: inline-block;
  border: 1px solid $grey-700;
  border-radius: 19px;
  padding: 7px 12px;

  & + .taxonomy-item {
    margin-left: 1rem;
  }
}
