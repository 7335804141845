.no-mobile-warning {
  header {
    background: #f2f2f2;
    border-bottom: 1px solid rgba($grey-700, 0.25);
    box-shadow: 0 5px 16px 0 rgba($black, 0.07);
    padding: 11px 16px;

    img {
      display: block;
      width: 43px;
      height: 22px;

      @media (min-width: 768px) {
        width: 54px;
        height: 28px;
      }
    }

    @media (min-width: 768px) {
      padding: 22px 16px;
    }
  }

  section {
    padding: 32px 20px;
    text-align: center;

    figure {
      position: absolute;
      z-index: -1;
      margin: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      max-width: 100vw;
      overflow: hidden;
    }

    img {
      display: block;
      position: relative;
      left: -30vw;
      width: 200vw;
      height: auto;

      @media (min-width: 768px) {
        left: 0;
        width: 100vw;
      }
    }

    a {
      display: inline-block;
      padding: 12px 49px 11px;
      background: $white;
      border: 1px solid $blue-500;
      line-height: 25px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5px;
      transition: background 0.2s ease-in;
      box-shadow: 0 2px 4px 0 rgba($black, 0.2);

      &:hover,
      &:focus {
        background: $blue-50;
      }
    }

    @media (min-width: 768px) {
      padding: 170px 20px;
    }
  }

  h1 {
    color: $blue-500;
    font-size: 22px;
    line-height: 1.4;
    margin: 0 auto 24px;
    max-width: 320px;

    @media (min-width: 768px) {
      max-width: none;
      font-size: 30px;
    }
  }

  p {
    color: $grey-700;
    font-size: 14px;
    line-height: 1.3;
    margin: 0 auto 32px;
    max-width: 320px;

    @media (min-width: 768px) {
      max-width: 450px;
      font-size: 18px;
    }
  }
}
