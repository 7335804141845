/* General Material Overrides */
.mat-menu-item:hover:not([disabled]) {
  background-color: $blue-50 !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding: 0 20px !important;
}

rnb-channel-list td.mat-cell,
rnb-channel-list th.mat-header-cell,
rnb-news-list td.mat-cell,
rnb-news-list th.mat-header-cell {
  padding: 0 20px;
}

rnb-channel-list th.mat-header-cell,
rnb-news-list th.mat-header-cell {
  white-space: nowrap;
}

.mat-tooltip {
  position: relative;
  background: $grey-900;
  border-radius: 0 !important;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 1.4;
}

.mat-raised-button.mat-secondary {
  border: 1px solid $blue-500;
  color: $blue-500;
  box-shadow: 0 2px 4px 0 rgba($black, 0.2);
  font-size: 16px;
}

.mat-raised-button.mat-warn {
  border: 1px solid $red-500;
  color: $red-500;
  background-color: transparent;
  box-shadow: 0 2px 4px 0 rgba($black, 0.2);
  font-size: 16px;
  &:disabled {
    border: 1px solid $grey-500;
    color: $grey-500;
    background-color: $grey-light-5;
  }
}

.rhd-toast-wrapper {
  $toast-wrapper-width: 1440px;

  position: absolute;
  top: -95px;
  left: 20px;
  margin: 0 auto;

  @media (min-width: $toast-wrapper-width) {
    left: calc((100vw - #{$toast-wrapper-width}) / 2);
  }
}

.rhd-toast {
  border-radius: 3px;

  .rhd__icon.rhd__icon-close::before {
    color: $grey-400;
  }
}

.rhd-toast__bottom-border {
  display: none;
}

.mat-menu-panel.add-news-menu {
  position: relative;
  top: 17px;
  left: -35px;
  border-radius: 0;

  button {
    font-size: 16px;
  }
}

.mat-cell rhd-button .rhd__btn--type-flat {
  padding: 5px 16px !important;
  background-color: $transparent;
}

/* CK Editor */
.ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
  z-index: 500;
}

.form-input .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding-left: 0;
}

.mat-hint {
  letter-spacing: normal;
  font-style: italic;
}
