/* Simple Select Component */
div.simple-select {
  display: flex;
  flex-direction: row;
  color: $grey-700;
  font-size: 16px;
  font-weight: 500;
  //line-height: 25px;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  transition: background 0.2s;
  cursor: pointer;

  &.is-active {
    background: $blue-50;
  }

  &:hover {
    background: $blue-75;
  }

  &:focus {
    background: $blue-150;
  }

  &-value {
    color: $blue-500;
    margin-left: 8px;
  }

  &-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  rhd-icon {
    font-size: 22px;
    margin-left: 8px;
    color: $grey-500;
    margin-bottom: -5px;
  }
}

.simple-select-menu {
  .is-active {
    background: $blue-50;
  }
}
