/* Dashboard Component */
div.dashboard {
  display: flex;
  justify-content: center;

  &-tile {
    cursor: pointer;
    flex-basis: 294px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 27px 24px 16px 25px;
    background: $white;
    color: $blue-500;
    box-shadow: 0.5px 0.5px 15px 4px rgba($grey-450, 0.3);

    &:hover {
      background-color: $blue-50;
      box-shadow: 0.4px 0.4px 4px 0px rgba($black, 0.2);
    }

    rhd-icon {
      font-size: 40px;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      margin: 5px 0 8px;
    }

    p {
      text-align: center;
      color: $grey-700;
      font-size: 16px;
      line-height: 25px;
      margin: 0;
    }
  }

  &-tile + &-tile {
    margin-left: 28px;
  }
}

div.dashboard + div.dashboard {
  margin-top: 64px;
}
