/* User Select Component */
rnb-user-select {
  display: inline-block;
  width: 100%;
}

div.user-select-container {
  //margin: 48px 0;
  cursor: text;

  &.user-select-container-active {
    .user-select {
      border-color: $blue-500;
    }

    rhd-icon {
      color: $blue-500;
    }
  }

  .user-select {
    padding: 11px 13px 11px 11px;
    display: flex;
    border: 1px solid $grey-500;
    font-size: 24px;
    line-height: 21px;
    background: $white;

    input {
      flex: 1;
      border: none;
      background: 0 0;
      font-size: 16px;
    }

    rhd-icon {
      cursor: pointer;
    }
  }

  .user-select + .user-selected-container {
    margin-top: 40px;
  }
}

rnb-selected-user + rnb-selected-user {
  display: block;
  margin-top: 23px;
}

.user-selected {
  display: flex;
  flex-direction: row;

  .user-avatar {
    flex-basis: 40px;
    margin: auto 0;

    img {
      max-width: 40px;
      max-height: 40px;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    color: $grey-900;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 12px;

    .user-comment {
      color: $grey-700;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
    }
  }

  .user-delete {
    flex-basis: 18px;
    font-size: 25px;
    color: $grey-700;
    margin: auto 0 auto 18px;

    rhd-icon {
      cursor: pointer;
    }
  }
}
.mat-option.loading {
  text-align: center;
  padding: 140px 0 120px;
}

.user-selected .user-identifier,
.mat-option .user-identifier {
  color: $grey-500;
  font-style: italic;
}
