/* Select Component */
div.select {
  display: flex;
  flex-direction: column;
  color: $grey-700;
  font-size: 16px;
  line-height: 19px;

  .select-header {
    color: $grey-900;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 16px;
  }

  .select-header-optional {
    color: $grey-700;
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    margin-left: 0.5em;
  }

  .select-wrapper {
    &.is-disabled {
      > * {
        pointer-events: none;
      }

      .select-toggle {
        border-color: $grey-400;
        color: $grey-500;

        rhd-icon {
          color: $grey-500;
        }
      }
    }
  }

  .select-toggle {
    flex-basis: content;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $grey-700;
    background-color: $white;
    padding: 16px 15px 15px 16px;
    cursor: pointer;

    .select-title {
      flex: 1;
    }

    rhd-icon {
      font-size: 24px;
      color: $blue-500;
    }
  }

  .select-body {
    display: none;
    border: 1px solid $grey-500;
    background-color: $white;

    &.select-open {
      display: flex;
      flex-direction: column;
    }

    .select-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: text;
      padding: 12px 15px 13px 15px;
      border-bottom: 1px solid $grey-500;

      input {
        flex: 1;
        border: none;
        background: 0 0;
        font-size: 16px;
        line-height: 25px;
        margin-left: 13px;
      }

      rhd-icon {
        font-size: 25px;

        &.pointer {
          cursor: pointer;
        }
      }
    }

    .select-values {
      max-height: 340px;
      overflow-y: auto;
    }

    .select-child-container {
      margin-left: 36px;
    }

    .select-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 30px 13px 16px;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $grey-200;
      }

      .mat-checkbox-inner-container {
        width: 24px;
        height: 24px;
      }

      &-label {
        flex: 1;
        margin-left: 12px;
      }

      rhd-icon {
        font-size: 25px;
      }
    }
  }

  .select-footer {
    flex-basis: 36px;
    border: 1px solid $grey-400;
    border-top: none;
    padding: 16px;

    mat-chip:not(.chip-placeholder) {
      color: $blue-500;
      border-color: $blue-300;

      .chip-name-wrapper {
        display: inline-block;
        overflow: hidden;
        height: 19px;
        max-width: 410px;
        white-space: pre;
        text-overflow: ellipsis;
      }

      mat-icon {
        color: $blue-500 !important;
        opacity: 1 !important;
      }
    }
  }
}
