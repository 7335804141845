.page-fab-wrapper {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 0;

  @media (min-width: $desktopBreakpoint) {
    width: calc(#{$desktopBreakpoint} + ((100vw - #{$desktopBreakpoint}) / 2) - 1.5rem);
    max-width: $desktopBreakpoint * 1.15;
  }
}

.page-fab--menu {
  a,
  button {
    font-size: 16px;
    color: $grey-900;
  }
}

button.page-fab.mat-fab {
  position: absolute;
  right: 1rem;
  bottom: 1.5rem;
  background-color: $white;
  color: $grey-900;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  rhd-icon {
    font-size: 24px;
    height: 24px;
  }
}
